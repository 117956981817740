<template>
  <div class="customer">
    <div class="content-form">
      <van-form @submit="onSubmit" :show-error="false">
        <van-field
          label="客户姓名"
          placeholder="请输入客户姓名"
          required
          v-model="formData.username"
          :rules="formDataRules.username"
          maxlength="10"
          clearable
        />
        <van-field
          v-model="formData.phone"
          label="联系电话"
          type="digit"
          placeholder="请输入电话号码"
          required
          :rules="formDataRules.phone"
          maxlength="11"
          clearable
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="formData.source"
          label="客户来源"
          placeholder="请选择渠道"
          @click="showSource = true"
          required
          :rules="formDataRules.source"
          clearable
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="formData.date"
          label="下次跟进时间"
          placeholder="选择日期时间"
          required
          :rules="formDataRules.date"
          @click="showPicker = true"
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            :loading="loading"
            loading-text="添加"
            native-type="submit"
            >添加</van-button
          >
        </div>
      </van-form>
      <!-- 客户来源 -->
      <van-popup v-model="showSource" position="bottom">
        <van-picker
          show-toolbar
          :columns="sourceList"
          @confirm="onConfirm"
          @cancel="showSource = false"
        />
      </van-popup>
      <!-- 日期 -->
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择年月日时分"
          :min-date="minDate"
          @confirm="onConfirmPicker"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import { addCustomer, getSelectOptionsList } from "@/api/api";
import moment from "moment/moment";
export default {
  data() {
    return {
      // 按钮加载
      loading: false,
      // 表单信息
      formData: {
        username: "",
        phone: "",
        source: "",
        // 选择客户来源的索引
        place: 0,
        date: "",
      },
      // 表单验证
      formDataRules: {
        username: [{ required: true, message: "请输入客户姓名" }],
        phone: [
          { required: true, message: "请输入电话" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式错误！" },
        ],
        source: [{ required: true, message: "请选择渠道" }],
        date: [{ required: true, message: "请选择日期时间" }],
      },
      // 客户来源列表显示与隐藏
      showSource: false,
      // 客户来源列表
      sourceList: [],
      // 时间列表显示与隐藏
      showPicker: false,
      minDate: new Date(),
      currentDate: new Date(),
    };
  },

  created() {
    // 客户来源列表
    this.getCustomerSource();
  },

  methods: {
    // 客户来源列表
    getCustomerSource() {
      getSelectOptionsList().then((res) => {
        if (res.code === 200) {
          this.sourceList = res.data.places;
        }
      });
    },
    // 客户来源选择
    onConfirm(value, index) {
      this.formData.place = index;
      if (this.formData.place === 0) {
        this.$toast("请选择客户来源");
      } else {
        this.formData.source = value;
        this.showSource = false;
      }
    },
    // 日期选择
    onConfirmPicker(time) {
      this.formData.date = moment(time).format("YYYY-MM-DD HH:mm:ss");
      this.showPicker = false;
    },
    // 表单提交
    onSubmit() {
      let follow = this.$route.query.follow;
      this.loading = true;
      addCustomer({
        name: this.formData.username,
        mobile: this.formData.phone,
        place: this.formData.place,
        time: this.formData.date,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.data);
            if (follow) {
              this.$router.push({ path: "/myFollow" });
            } else {
              this.$router.push({ path: "/myClient" });
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer {
  margin-top: 22px;
  padding: 0 22px;
  .content-form {
    margin-top: 55px;
    min-height: 345px;
    background: #ffffff;
    box-shadow: 0px 6px 28px 1px rgba(40, 40, 40, 0.15);
    border-radius: 10px;
    padding: 10px;
    /deep/.van-cell {
      padding: 0.36667rem 0.42667rem;
    }
    /deep/.van-field__label {
      margin-right: 2px;
      span {
        font-size: 14px;
      }
    }
    /deep/.van-field__control {
      font-size: 14px;
    }
    /deep/.van-button--info,
    /deep/.van-button--primary {
      background-color: #f3973b;
      border: #f3973b;
    }
    #code-primary {
      background-color: #f3973b;
      border: #f3973b;
    }
    #code-primary-active {
      background-color: #999;
      border: #999;
    }
  }
}
</style>